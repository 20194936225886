<template lang='pug'>
    div
        report-list
</template>

<script>
import ReportList from '../components/LicensingReport/ReportList';

export default {
    components: {
        ReportList,
    },
};
</script>
